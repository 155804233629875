@import '../../../../scss/theme-bootstrap';

.cart-fineprint {
  &__heading {
    @include h1;
    margin: 0 0 25px 0;
  }
  &__description {
    // needed to override styling
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 5px;
    }
  }
}
